<template>
<div class="authentication">
  <Header
  :languagepick="false"
  :settings="settings" />
  <b-container class="login-card-container">
    <b-card class="auth-card">
      <form name="form" @submit.prevent="handleAuthentication">
        <div v-if="!successful">
          <!-- Email -->
          <div class="form-group">
            <label for="Email">{{ $t('email') }}</label>
            <input
              v-model="user.email"
              v-validate="'required|min:3|max:50'"
              type="text"
              class="form-control"
              name="Email"
            />
            <div
              v-if="submitted && errors.has('Email')"
              class="alert-danger"
            >
              {{ errors.first("Email") }}
            </div>
          </div>
          <!-- Firstname -->
          <div class="form-group" v-if="authenticationType == 'basic'">
            <label for="firstname">{{ $t('firstname') }}</label>
            <input
              v-model="user.firstname"
              v-validate="'required|max:50'"
              type="text"
              class="form-control"
              name="firstname"
            />
            <div v-if="submitted && errors.has('firstname')" class="alert-danger">
              {{ errors.first("firstname") }}
            </div>
          </div>
          <!-- Lastname -->
          <div class="form-group" v-if="authenticationType == 'basic'">
            <label for="lastname">{{ $t('lastname') }}</label>
            <input
              v-model="user.lastname"
              v-validate="'required|max:40'"
              type="text"
              class="form-control"
              name="lastname"
            />
            <div
              v-if="submitted && errors.has('lastname')"
              class="alert-danger"
            >
              {{ errors.first("lastname") }}
            </div>
          </div>
          
          <!-- Code -->
          <div class="form-group" v-if="authenticationType == 'code'">
            <label for="code">{{ $t("code") }}</label>
            <input
              v-model="user.code"
              v-validate="'required|max:50'"
              type="text"
              class="form-control"
              name="code"
            />
            <div v-if="errors.has('code')" class="alert-danger">
              {{ errors.first("code") }}
            </div>
          </div>
          <!-- GDPR -->
          <div>
            <router-link v-if="getLang == 'nl_NL'" to="/cookie-privacy-nl">
              <a class="link-privacy">{{ $t("policy") }}</a><br/>
            </router-link>
             <router-link v-if="getLang == 'fr_FR'" to="/cookie-privacy-fr">
              <a class="link-privacy">{{ $t("policy") }}</a><br/>
            </router-link>
            <input
              type="checkbox"
              v-model="accepted"
              v-validate="'required'"
              name="terms"
              value="accepted"
            />
              {{ $t("terms") }}
            <div v-if="errors.has('terms')" class="alert-danger">
              {{ errors.first("terms") }}
            </div>
          </div>
          <!-- Submit  -->
          <div class="form-group">
            <b-button type="submit" block>{{ $t('signup') }}</b-button>
          </div>
        </div>
      </form>

      <div
        v-if="message"
        class="alert"
        :class="successful ? 'alert-success' : 'alert-danger'"
      >
        {{ message }}
      </div>
    </b-card>
  </b-container>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
// import Footer from '@/components/Footer.vue'

export default {
  name: "Authentication",
  components: {
    Header,
    // Footer,
  },
  data() {
    return {
      user: { email: "", firstname: "", lastname: "", code: ""},
      submitted: false, 
      successful: false,
      accepted: false,
      message: "",
    }
  },
  watch: {
    // Checks if user is logged in with basic Auth, push to home when true
    isLoggedIn: function() {
      if(this.isLoggedIn == true){
        this.$router.push("/home")
      }
    }
  },
  computed: {
    getLang() {
      return localStorage.getItem("lang")
    },
    isLoggedIn() {
      return this.$store.getters['Auth/isLoggedIn']
    },
    settings() {
      return this.$store.state.Config.settings;
    },
    authenticationType() {
      return this.$store.getters["Config/authenticationType"]
    }
  },
  mounted() {
    if (this.isLoggedIn) {
      this.$router.push("/home")
    }
  },
  methods: {
    handleAuthentication() {
      this.message = "";
      this.submitted = true;

      if (this.authenticationType == "basic") {
        this.$validator.validate().then((isValid) => {
        if (isValid) {
          this.$store.dispatch('Auth/register', this.user).then(
            (data) => {
              this.message = data.message;
              this.successful = true;
            },
            (error) => {
              this.message = error;
              this.successful = false;
            }
          );
        }
      });
      } else if (this.authenticationType == "code") {
        this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
         this.loading = false
         return
        }
        if (this.user.email && this.user.code) {
          this.$store.dispatch('Auth/login', this.user).then(
            (data) => {
              if (!data.succesful) this.message = data.error
              this.$router.push("/home")
            },
            (error) => {
              this.loading = false
              this.message = error
            }
          );
        }
      })
      }
    }
  }
}
</script>
<style lang="scss">
$color-primary: var(--primary);
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);
$background-image: var(--theme-background-image);
   .authentication {
  flex-grow: 1;
  }

  .auth-card {
    max-width: 20rem;
    margin: 0 auto;
    vertical-align: center;
  }
  .login-card-container {
    min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
    min-height: 75vh; /* These two lines are counted as one :-)       */

    display: flex;
    align-items: center;

  }

  .link-privacy {
    color: $color-secondary;
  }
</style>