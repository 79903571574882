<template>
  <div>
    <Header />
    <b-tabs>
      <b-tab title="Cookies">
        <div class="cookie-doc">
          Nous, <strong>To the Point Events</strong>, dont le siège social est situé à
          Kempische Steenweg 303 b21 3500 Hasselt, inscrit sous le numéro
          0477.191.005 (dénommé ci-après, 'To the Point Events' ou 'nous'), utilisons
          principalement des cookies pour améliorer l’expérience de navigation
          des visiteurs sur notre site
          <router-link to="/">{{url}}</router-link>. Toutes les
          données que nous obtenons grâce à nos cookies sont utilisées
          exclusivement pour comprendre votre utilisation de notre site Web, en
          vue d’améliorer les performances, la fonctionnalité et la convivialité
          de notre site Web. En utilisant les informations et services sur
          <router-link to="/">{{url}}</router-link>, vous confirmez
          que vous avez lu et compris notre politique en matière de cookies et
          les conditions que nous avons incluses ici. Un cookie est un petit
          fichier texte et numérique que nous envoyons et stockons dans votre
          navigateur, sur votre dispositif mobile et/ou sur le disque dur de
          votre ordinateur lors de votre visite à notre site Web. Il nous permet
          de nous souvenir de vos préférences lorsque vous utilisez notre site
          (ex. votre choix de langue et d'autres préférences d'utilisation),
          sauf si vous avez modifié les paramètres de votre navigateur pour
          qu’il refuse les cookies. Il veille au bon fonctionnement des sites,
          mais peut aussi recueillir des informations à des fins commerciales
          <h3>Types de cookies</h3>
          Les cookies peuvent être classés par origine, par fonction et par
          durée de vie : Classés par origine, on peut distinguer les cookies
          gérés par notre entreprise, d’une part, et les cookies gérés par
          d’autres entreprises qui exécutent cette gestion pour notre compte,
          d’autre part. Nous n’utilisons pas de cookies de tierces parties
          permettant ces tierces parties d’utiliser les données obtenues pour
          leur propre usage. Classés par fonction, on distingue :
          <ul>
            <li>
              <strong>Les cookies indispensables</strong> sont (comme
              l’indiquent leur nom) nécessaires pour utiliser un site Web,
              pouvoir offrir certaines fonctions demandées par vous et assurer
              un contrôle d’identité sécurisé ;
            </li>
            <li>
              <strong>Les cookies fonctionnels</strong> sont des cookies qui
              veillent au bon fonctionnement d’un site Web. Ils mémorisent les
              informations sur vos choix et préférences sur notre site Web (ex.
              des cookies de connexion, d’enregistrement, de choix de langue ou
              des produits dans votre panier). Les cookies fonctionnels sont, en
              toute logique, des cookies internes ;
            </li>
            <li>
              <strong>Les cookies aux fins statistiques/analytiques</strong>
              (appelés également ‘des cookies de performance’) nous permettent
              de compter les visiteurs et leurs origines afin de mesurer et
              d’améliorer les performances de notre site Web. Ils nous aident à
              savoir quelles pages sont les plus et les moins populaires et
              comment les visiteurs se déplacent à travers le site Web (Google
              Analytics, Google Tag Manager, Hotjar).
            </li>
            <li>
              <strong>Les cookies aux fins sociales</strong> permettent à
              l'utilisateur, via les médias sociaux, de partager directement
              avec d'autres le contenu du site web visité (Facebook, Twitter,
              Linkedin, Whatsapp).
            </li>
            <li>
              <strong>Les cookies aux fins de ciblage</strong> permettent de
              suivre le comportement de navigation des visiteurs et de composer
              un profil basé sur votre comportement, pour que les annonces
              montrées soient ajustées à vos intérêts (Facebook). Il est
              obligatoire de demander et d’obtenir l’autorisation pour utiliser
              de tels cookies.
            </li>
            <li>
              <strong>Les cookies aux fins commerciales</strong> recensent le
              nombre et la nature des annonces publicitaires vues par un
              utilisateur.
            </li>
          </ul>
          Les cookies peuvent également être subdivisés selon leur durée de vie.
          On distingue :
          <ul>
            <li>
              <strong>Les cookies permanents</strong> : ces cookies sont des
              cookies qui restent présents sur l’appareil de l’utilisateur
              pendant la durée déterminée par le cookie. Ils sont activés à
              chaque fois que l’utilisateur visite le site web ayant placé ce
              cookie (ex. cookies placés par les réseaux sociaux, tels que
              Facebook, Google Analytics, etc.). La plupart des cookies
              non-fonctionnels sont des cookies permanents.
            </li>
            <li>
              <strong>Les cookies de session : </strong>ces cookies sont des
              cookies qui nous permettent de simplifier et de connecter les
              opérations effectuées par un utilisateur pendant une session de
              navigation. Une session de navigation commence lorsqu’un
              utilisateur ouvre l’écran de navigation et se termine lorsque
              l’écran de navigation est fermé. Les cookies de session sont
              placés temporairement. Dès la fermeture du navigateur, l’ensemble
              des cookies de session est supprimé. La plupart des cookies
              fonctionnels sont des cookies de session.
            </li>
          </ul>
          <h3>Cookies utilisés</h3>
          Ci-dessous, vous pouvez vérifier, par cookie utilisé, la gestion, la
          fonction et la durée de vie. Dans le cas où les cookies que nous
          utilisons, collectent et traient des données personnelles, cela est
          fait conformément notre
          <a
            href="https://www.website.be/privacy"
            target="_blank"
            rel="noopener noreferrer"
            >politique de confidentialité</a
          >. Vous y trouverez également vos droits en matière de données
          personnelles.
          <h4>Wordpress</h4>
          Nous utilisons WordPress pour le développement de sites Web.
          <a href="https://cookiedatabase.org/service/wordpress">Lire plus</a>

          <strong>Type: fonctionnel</strong>
          <table>
            <tbody>
              <tr>
                <td>Nom</td>
                <td>Rétention</td>
                <td>Function</td>
              </tr>
              <tr>
                <td>wordpress_test_cookie</td>
                <td>aucun</td>
                <td>Vérifie si des cookies peuvent être placés.</td>
              </tr>
              <tr>
                <td>wordpress_logged_in_*</td>
                <td>permanent</td>
                <td>Garde les utilisateurs connectés.</td>
              </tr>
              <tr>
                <td>wordpress_sec_*</td>
                <td>15 jours</td>
                <td>
                  Fournit une protection contre les pirates informatiques,
                  stocke les détails du compte.
                </td>
              </tr>
            </tbody>
          </table>
          &nbsp; Ces données ne sont pas partagées avec des tiers.
          <h4>Compliance</h4>
          Nous utilisons Complianz pour l'enregistrement du consentement des
          cookies.
          <a href="https://cookiedatabase.org/service/complianz">Lire plus</a>

          <strong>Type: fonctionnel</strong>
          <table>
            <tbody>
              <tr>
                <td><b>Nom</b></td>
                <td><b>Rétention</b></td>
                <td><b>Function</b></td>
              </tr>
              <tr>
                <td>complianz_policy_id</td>
                <td>1 an</td>
                <td>Enregistre l'ID de politique de cookies accepté.</td>
              </tr>
              <tr>
                <td>complianz_consent_status</td>
                <td>1 an</td>
                <td>Enregistre la préférence de cookie.</td>
              </tr>
              <tr>
                <td>cmplz_marketing</td>
                <td>1 an</td>
                <td>Enregistre la préférence de cookie.</td>
              </tr>
            </tbody>
          </table>
          &nbsp; Pour plus d'informations, lisez la politique de confidentialité
          de <a href="https://complianz.io/privacy-statement/">Complianz</a>.
          <h4>WPML</h4>
          Nous utilisons WPML pour la gestion des langues.
          <a href="https://cookiedatabase.org/service/wpml">Lire plus</a>

          <strong>Type: fonctionnel</strong>
          <table>
            <tbody>
              <tr>
                <td><b>Nom</b></td>
                <td><b>Rétention</b></td>
                <td><b>Function</b></td>
              </tr>
              <tr>
                <td>wp-wpml_current_language</td>
                <td>1 jour</td>
                <td>Enregistre les préférences de langue.</td>
              </tr>
            </tbody>
          </table>
          &nbsp; Ces données ne sont pas partagées avec des tiers.
          <h4>Google Analytics</h4>
          Nous utilisons Google Analytics pour les statistiques du site Web.
          <a href="https://cookiedatabase.org/service/google-analytics"
            >Lire plus</a
          >

          <strong>Type: statistiques (anonyme sans ip)</strong>
          <table>
            <tbody>
              <tr>
                <td><b>Nom</b></td>
                <td><b>Rétention</b></td>
                <td><b>Function</b></td>
              </tr>
              <tr>
                <td>_ga</td>
                <td>2 ans</td>
                <td>
                  Suit et recueille des analyses de pages vues pour les
                  statistiques du site Web.
                </td>
              </tr>
              <tr>
                <td>_gat</td>
                <td>1 minute</td>
                <td>Filtre les demandes des bots.</td>
              </tr>
              <tr>
                <td>_gid</td>
                <td>1 jour</td>
                <td>
                  Suit et recueille des analyses de pages vues pour les
                  statistiques du site Web.
                </td>
              </tr>
            </tbody>
          </table>
          &nbsp; Pour plus d'informations, lisez la politique de confidentialité
          de <a href="https://policies.google.com/privacy">Google Analytics</a>.
          <h4>Google Fonts</h4>
          Nous utilisons Google Fonts pour afficher les polices Web.
          <a href="https://cookiedatabase.org/service/google-fonts"
            >Lire plus</a
          >

          <strong>Type: third-party (marketing/tracking)</strong>
          <table>
            <tbody>
              <tr>
                <td><b>Nom</b></td>
                <td><b>Rétention</b></td>
                <td><b>Function</b></td>
              </tr>
              <tr>
                <td>Google Fonts API</td>
                <td>aucun</td>
                <td>Demande l'adresse IP de l'utilisateur.</td>
              </tr>
            </tbody>
          </table>
          &nbsp; Pour plus d'informations, lisez la politique de confidentialité
          de <a href="https://policies.google.com/privacy">Google Fonts</a>.
          <h4>Vimeo</h4>
          Nous utilisons Vimeo pour montrer des vidéos.
          <a href="https://cookiedatabase.org/service/vimeo">Lire plus</a>

          <strong>Type: statistics</strong>
          <table>
            <tbody>
              <tr>
                <td><b>Nom</b></td>
                <td><b>Rétention</b></td>
                <td><b>Function</b></td>
              </tr>
              <tr>
                <td>__utmt_player</td>
                <td>10 minutes</td>
                <td>Suit la portée du public cible.</td>
              </tr>
              <tr>
                <td>vuid</td>
                <td>2 ans</td>
                <td>Stocke l'historique d'utilisation de l'utilisateur.</td>
              </tr>
            </tbody>
          </table>
          &nbsp; Pour plus d'informations, lisez la politique de confidentialité
          de <a href="https://vimeo.com/privacy">Vimeo</a>.
          <h4>RumbleTalk</h4>
          Nous utilisons RumbleTalk pour permettre aux utilisateurs enregistrés
          de discuter en groupe, de discuter des appels vidéo et audio 1-sur-1
          ou 1-sur-1.
          <a href="https://cookiedatabase.org/service/vimeo">Lire plus</a>

          <strong>Type: third-party (marketing/tracking)</strong>
          <table>
            <tbody>
              <tr>
                <td><b>Nom</b></td>
                <td><b>Rétention</b></td>
                <td><b>Function</b></td>
              </tr>
              <tr>
                <td>AWSALBCORS</td>
                <td>1 semaine</td>
                <td>
                  Cookie d'Amazon pour l'accès CORS. Il est nécessaire pour
                  accéder aux ressources CORS dans Amazon.
                </td>
              </tr>
              <tr>
                <td>s</td>
                <td>session</td>
                <td>
                  Un cookie de session ; il est utilisé pour connecter
                  l'utilisateur à sa session en cours. Il est supprimé lorsque
                  l'onglet est fermé et créé lorsque l'onglet est ouvert.
                </td>
              </tr>
              <tr>
                <td>r</td>
                <td>2 semaines</td>
                <td>
                  Ce cookie est défini lorsque l'utilisateur se connecte et est
                  utilisé pour garder l'utilisateur connecté.
                </td>
              </tr>
            </tbody>
          </table>
          &nbsp; Pour plus d'informations, lisez la politique de confidentialité
          de
          <a href="https://rumbletalk.com/about_us/privacy_policy/"
            >RumbleTalk</a
          >.
          <h4>Slido</h4>
          Nous utilisons Slido pour donner aux utilisateurs enregistrés la
          possibilité de poser des questions tout en suivant un flux en direct
          ou de participer à des sondages.

          <strong>Type: third-party (marketing/tracking)</strong>
          <table>
            <tbody>
              <tr>
                <td><b>Nom</b></td>
                <td><b>Rétention</b></td>
                <td><b>Function</b></td>
                <td><b>Category</b></td>
              </tr>
              <tr>
                <td>__exponea_(*), xnpe_(*), __EX(*)</td>
                <td>3 ans</td>
                <td>
                  Ces cookies sont utilisés pour collecter des informations sur
                  la manière dont les visiteurs utilisent les fonctionnalités et
                  notre site Web. Nous utilisons ces informations pour compiler
                  des rapports et améliorer notre site Web. Les cookies
                  collectent des informations d'une manière qui n'identifie
                  directement personne jusqu'à ce qu'il crée un compte Slido.
                </td>
                <td>analytical / performance</td>
              </tr>
              <tr>
                <td>_fbp fr, act, oo, presence, spin, wd, xs, sb, sfau, wd</td>
                <td>3 mois</td>
                <td>
                  Utilisé par Facebook pour diffuser une série de produits
                  publicitaires tels que les enchères en temps réel d'annonceurs
                  tiers.
                </td>
                <td>marketing/tracking</td>
              </tr>
              <tr>
                <td>Slido.Privacy</td>
                <td>10 ans</td>
                <td>
                  Stocke l'état de consentement des cookies de l'utilisateur
                  pour le domaine actuel.
                </td>
                <td>essentiel</td>
              </tr>
              <tr>
                <td>
                  slido_(*) Slido.(*) currentAccountUuid AWSALB _passenger_route
                </td>
                <td>10 ans</td>
                <td>
                  Technique, nécessaire à notre infrastructure (le cookie
                  contient un token pour vérifier la session de connexion etc
                  ...).
                </td>
                <td>essentiel</td>
              </tr>
              <tr>
                <td>
                  JsessionID TSNGUID optimizelyEndUserId _gcl_au _mkto_trk s_(*)
                </td>
                <td>session</td>
                <td>
                  Nous utilisons le service New Relic pour surveiller l'état de
                  disponibilité / indisponibilité de nos sites Web.
                </td>
                <td>session</td>
              </tr>
              <tr>
                <td>_persistenceTest</td>
                <td>session</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
          &nbsp; Pour plus d'informations, lisez la politique de confidentialité
          de <a href="https://www.sli.do/terms#privacy-policy">Slido</a>.
          <h4>Zoom</h4>
          Nous utilisons Zoom pour permettre aux utilisateurs enregistrés de se
          connecter les uns aux autres via des conversations de groupe vidéo.

          <strong>Type: fonctionnel</strong>
          <table>
            <tbody>
              <tr>
                <td><b>Nom</b></td>
                <td><b>Rétention</b></td>
                <td><b>Function</b></td>
              </tr>
              <tr>
                <td>_zm_lang</td>
                <td>1 an</td>
                <td>Enregistre les préférences de langue.</td>
              </tr>
            </tbody>
          </table>
          &nbsp; Pour plus d'informations, lisez la politique de confidentialité
          de Zoom
          <a href="https://zoom.us/privacy-and-legal">Privacy Policy</a>.
          <h3>Accepter des cookies</h3>
          Lors de votre première visite à notre site Web, vous serez invité à
          accepter les différents types de cookies utilisés et pour lesquels
          votre autorisation est requise. Vous bloquez les cookies en activant
          les paramètres du navigateur permettant de refuser les cookies.
          Lorsque vous utilisez vos paramètres de navigateur pour bloquer ces
          cookies, il se peut que vous n'ayez pas accès à (certaines parties de)
          notre site Web. Pour obtenir plus d'informations sur les cookies,
          lisez attentivement notre politique en matière de cookies. Si, à un
          moment donné, vous voudriez retirer votre autorisation, vous pouvez
          toujours supprimer vos cookies via les paramètres de votre navigateur
          <h3>Supprimer ou désactiver des cookies</h3>
          Si vous ne voulez pas que des sites Web placent des cookies sur votre
          ordinateur ou vous voulez supprimer vos cookies, vous pouvez changer
          les paramètres de cookie dans votre navigateur. La plupart du temps,
          ces paramètres se retrouvent dans le menu ‘Options’ ou ‘Préférences’
          de votre navigateur. Vous pouvez également adapter vos paramètres pour
          que votre navigateur refuse tous les cookies ou uniquement les cookies
          des tiers. Cependant, si vous décidez de ne pas utiliser des cookies,
          nous ne pouvons pas garantir le bon fonctionnement de notre site Web.
          Il se peut alors que certaines fonctions du site Web disparaissent ou
          que vous ne puissiez plus visionner certaines pages. En outre, en
          refusant les cookies, cela ne signifie pas que vous ne verrez plus de
          publicités. Seulement, elles ne seront plus adaptées à vos intérêts et
          seront plus souvent répétées. La manière dont vous pouvez adapter vos
          paramètres varie en fonction de votre navigateur. Afin de mieux
          comprendre ces paramètres, vous pouvez consulter la fonction ‘Aide’
          dans votre navigateur pour plus de détails. Retrouvez des informations
          supplémentaires et pratiques sur les cookies sur :
          <a href="http://www.allaboutcookies.org"
            >http://www.allaboutcookies.org</a
          >
          et
          <a href="http://www.youronlinechoices.com/be-fr"
            >http://www.youronlinechoices.com/be-fr</a
          >.
          <h3>Vous avez des questions ?</h3>
          Pour plus d’informations concernant cette politique en matière de
          cookies, veuillez nous contacter sur
          <a href="info@tothepointevents.be">info@tothepointevents.be</a>.
          <h3>Changements</h3>
          De temps à autres, nous pouvons changer ou compléter cette politique
          en matière de cookies sans avertissement préalable. Par conséquent,
          nous vous invitons à consulter toujours la dernière version de cette
          politique sur notre site Web. Il va sans dire que nous demanderons
          toujours votre autorisation pour l’utilisation de certains cookies.
        </div>
      </b-tab>
      <b-tab title="Privacy">
        <div class="privacy-doc">
          <h3>Notre approche du respect de votre vie privée</h3>
          Nous vous remercions de nous avoir confié vos données personnelles.
          Nous, <strong>To The Point Events</strong>, dont le siège social est situé à
          Walter Thijsstraat 2, 3500 Hasselt, inscrit sous le numéro
          0886.029.276, sommes responsables du traitement de vos données
          personnelles par rapport à votre future participation au
          <strong>{{client}} </strong>(dénommé ci-après ‘l’Événement’). À cet
          égard, nous agissons en tant que responsables du traitement de données
          de vos informations personnelles qui détermine les finalités et les
          moyens du traitement de données à caractère personnel dans le cadre du
          règlement (EU) 2016/679 (« RGPD ») Nous prenons votre vie privée très
          au sérieux et nous nous engageons à la protéger en se conformant à
          cette déclaration de confidentialité. Nous nous engageons à être
          transparents avec vous en fournissant des informations claires sur les
          données personnelles que nous recueillons ; comment nous l’utilisons
          et pourquoi ; à qui nous les communiquons ; comment nous protégeons
          les données personnelles et quels choix vous avez par rapport à
          l’utilisation de vos données personnelles par nous et par de tierces
          parties. Nous conservons le droit d’adapter cette déclaration de
          confidentialité à tout moment si de nouveaux développements le
          justifient. La version la plus récente de la déclaration de
          confidentialité peut toujours être consultée sur le site Web
          <router-link to="/">{{url}}</router-link>.
          <h3>Quelles données personnelles traitons-nous et pourquoi ?</h3>
          Le ‘traitement’ des données personnelles implique toute opération ou
          tout ensemble d'opérations effectuées ou non à l'aide de procédés
          automatisés et appliquées à des données ou des ensembles de données à
          caractère personnel, telles que la collecte, l'enregistrement,
          l'organisation, la structuration, la conservation, l'adaptation ou la
          modification, l'extraction, la consultation, l'utilisation, la
          communication par transmission, la diffusion ou toute autre forme de
          mise à disposition, le rapprochement ou l'interconnexion, la
          limitation, l'effacement ou la destruction de ces données
          personnelles. Cette section vous explique quelles données personnelles
          nous traitons, où nous les avons obtenues et pourquoi. Si vous
          souhaitez participer à l’Événement – qui sera organisé de manière
          digitale sur une plateforme accessible par le biais du domaine
          <router-link to="/">{{url}}</router-link> (dénommé ci-après
          ‘la Plateforme’) – cette participation nécessite un enregistrement au
          préalable sur <router-link to="/">{{url}}</router-link>.
          Cet enregistrement au préalable nécessite, à son tour, que certaines
          données personnelles soient partagées avec nous. Ces données
          personnelles peuvent être :
          <ul>
            <li>
              Des données d’identification qui incluent votre prénom, nom, nom
              de jeune fille, nom d’utilisateur ou un identifiant similaire,
              photo de profil, titre, situation professionnelle et genre. Cela
              peut également inclure d’autre contenu audiovisuel dans lequel
              vous apparaissez en contribuant ou en participant à l’Événement ;
            </li>
            <li>
              Des données de contact qui incluent votre adresse mail, adresse
              d’entreprise, pays ;
            </li>
            <li>
              Des données techniques qui incluent vos données d’enregistrement,
              type et version de navigateur, des information matérielles, votre
              fuseau horaire et localisation ainsi que d’autres technologies sur
              les appareils que vous utilisez pour accéder à la Plateforme ;
            </li>
            <li>
              Des données de profil qui incluent votre nom d’utilisation et
              votre mot de passe, vos préférences, retours et réponses sur des
              questionnaires ;
            </li>
            <li>
              Des données d’utilisation qui incluent de l’information sur
              comment vous utilisez les services de la Plateforme.
            </li>
            <li>
              Nous collectons, utilisons et partageons également des données
              agréées comme des données statistiques ou démographiques à toutes
              fins. Des données agréées peuvent être dérivées de vos données
              personnelles, mais ne sont pas considérées comme des données
              personnelles puisqu’elles ne révèlent pas votre identité de
              manière directe ou indirecte.
            </li>
          </ul>
          À part ces données que vous partagez de manière active en remplissant
          le formulaire d’enregistrement, nous pouvons également traiter des
          données liées à l’adresse IP que vous utilisez afin d’améliorer le
          fonctionnement des services liés à l’Événement. Quand l’enregistrement
          est complété, un e-mail est envoyé au participant enregistré,
          confirmant sa participation et la possibilité de demander son mot de
          passe à nouveau ou de le changer. L’accès à la plateforme sera
          débloqué uniquement quand l’Événement aura lieu.
          <h3>
            Quelles sont les finalités du traitement auxquelles les données
            personnelles sont destinées et quelle est la base légale pour un
            traitement pareil ?
          </h3>
          Tout d’abord, nous collectons des données personnelles sur base de
          votre consentement informé. Dans la mesure nécessaire, nous collectons
          également des données personnelles afin de permettre à l’usager de
          participer à des activités ayant lieu sur la Plateforme et, par
          conséquent, mettre en œuvre l’accord que nous avons conclu avec vous
          concernant la participation à l’Événement. Enfin, nous collectons vos
          données personnelles pour l’application de nos intérêts légitimes en
          tant que responsable, à savoir afin de recevoir votre retour sur nos
          services et propositions.<strong> </strong>
          <h3>La protection de vos données personnelles</h3>
          Nous ne vendons ni n’échangeons vos données personnelles à des tierces
          parties. Nous divulguons des données personnelles uniquement à des
          fournisseurs de services autorisés (voir ci-dessous). Le nombre de
          personnes ayant accès aux données personnelles de l’utilisateur est
          limité. L’accès est accordé uniquement dans la mesure où l’information
          concernée est nécessaire pour la personne pour mener sa tâche à bien.
          De toute façon, ces personnes autorisées sont liées par l’obligation
          de confidentialité. Nous avons mis en œuvre des mesures raisonnables
          afin de protéger vos données personnelles contre la destruction, la
          perte, l’altération, la divulgation ou l’accès non-autorisée
          conformément aux dispositions du règlement (EU) 2016/679 (« RGPD »).
          <h3>Le partage de vos données</h3>
          Nous vous informons que l’Evénement sera géré par
          <strong>subverwerker</strong>, dont le siège social est situé à adres
          subverwerker, qui se chargera également – à la suite d’une
          autorisation écrite émanant de nous – du traitement et de la gestion
          des données personnelles collectées à cette fin et à cet égard. Nous
          avons également autorisé subverwerker à utiliser des sous-processeurs
          dédiés offrant suffisamment de garanties pour mettre en œuvre des
          mesures techniques et organisationnelles appropriées de manière que le
          traitement réponde aux exigences stipulées dans le règlement RGPD et
          assure la protection des droits de la personne concernée.
          <h3>
            Pendant combien de temps traitons-nous vos données personnelles ?
          </h3>
          Nous traitons les données personnelles de l’utilisateur lors de la
          durée de l’Événement. Après l’Événement, vos données personnelles
          seront immédiatement supprimées de manière permanente.
          <h3>Quelles sont vos droits à la vie privée ?</h3>
          Cette section explique les droits que vous avez concernant vos données
          personnelles. Si vous souhaiter exercer un de vos droits, ou recevoir
          plus d’informations les concernant, veuillez nous contacter en
          utilisant les coordonnées qui se trouvent à la fin de cette
          Déclaration de confidentialité et nous vous aiderons.
          <h5>Droits à l’accès</h5>
          Vous avez le droit d’obtenir la confirmation quant à savoir si nous
          traitons ou non vos données personnelles et, si nous les traitons, de
          demander l’accès à vos données personnelles ainsi que de l’information
          par rapport au traitement de vos données. Si vous avez des questions
          ou si vous voulez en savoir plus sur le type de données personnelles
          que nous traitons, nous vous invitons à prendre contact avec nous et
          nous vous donnerons plus d’informations.
          <h5>Droit à la rectification</h5>
          Si vous croyez que nous stockons des données personnelles incorrectes,
          comme un nom mal épelé, un ancien nom ou une ancienne adresse, vous
          pouvez toujours nous demander de le corriger.
          <h5>Droit à l’effacement / droit à l’oubli</h5>
          Vous avez le droit de demander d’effacer vos données personnelles de
          manière permanente de nos dossiers.
          <h5>Droit de retirer son consentement</h5>
          Dans le cas où le traitement de vos données personnelles est basé sur
          votre consentement, vous avez le droit de retirer votre consentement à
          tout moment, sans incidence sur la légalité du traitement basé sur le
          consentement donné avant ce retrait. Veuillez noter que tous les
          aspects d’une campagne de marketing entamée ne peuvent pas s’arrêter
          une fois démarrés après un retrait de consentement.
          <h5>
            Droit de déposer une plainte auprès d’une autorité de contrôle
          </h5>
          Si vous n’êtes pas satisfait de la manière dont nous traitons vos
          données personnelles, vous avez le droit de déposer une plainte contre
          nous auprès de l’autorité de contrôle compétente ; pour la Belgique :
          l’Autorité de protection des données ou « APD ». Vous trouverez leurs
          coordonnées ci-dessous.
          <h3>Qui contacter ?</h3>
          Si vous avez des questions sur notre déclaration de confidentialité ou
          sur nos pratiques, nous vous invitons à prendre contact avec nous.
          Vous pouvez contacter <strong>To The Point Events</strong> comme le gestionnaire
          général de l’Événement sur :
          <a href="info@tothepointevents.be">info@tothepointevents.be</a>. Si vous avez
          une plainte au sujet du traitement de vos données personnelles, vous
          pouvez contacter l’Autorité de protection des données : Rue de la
          Presse 35, 1000 Bruxelles E-mail :
          <a href="mailto:contact@apd-gba.be">contact@apd-gba.be</a>
          Numéro téléphone : +32 (0)2 274 48 00
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import Header from "@/components/Header";
// import Footer from '@/components/Footer'

export default {
  components: { Header },
  data() {
    return {
      url: window.location.hostname,
      compliance_items: [
        {
          Naam: "complianz_policy_id",
          retentie: "1 jaar",
          Functie: "Registreert de geaccepteerde cookiebeleid-ID.",
        },
        {
          Naam: "complianz_content_status",
          retentie: "1 jaar",
          Functie: "Slaat cookievoorkeur op.",
        },
        {
          Naam: "complianz_cmplz_marketing",
          retentie: "1 jaar",
          Functie: "Slaat cookievoorkeur op.",
        },
      ],
      wpml_items: [
        {
          Naam: "wp-wpml_current_language",
          Retentie: "1 dag",
          Functie: "Slaat taalvoorkeuren op.",
        },
      ],
      googleAnal_items: [
        {
          Naam: "_ga",
          Retentie: "2 jaar",
          Functie:
            "Volgt en verzamelt analyse van paginaweergaven voor websitestatistieken.",
        },
        {
          Naam: "_gat",
          Retentie: "1 minuut",
          Functie: "Filtert verzoeken van bots.",
        },
        {
          Naam: "_gid",
          Retentie: "1 dag",
          Functie:
            "Volgt en verzamelt analyse van paginaweergaven voor websitestatistieken.",
        },
      ],
      googleFont_items: [
        {
          Naam: "Google Fonts API",
          Retentie: "geen",
          Functie: "Vraagt het IP-adres van de gebruiker op.",
        },
      ],
      vimeo_items: [
        {
          Naam: "__utmt_player",
          Retentie: "10 minuten",
          Functie: "Houdt het bereik van de doelgroep bij.",
        },
        {
          Naam: "vuid",
          Retentie: "2 jaar",
          Functie: "Slaat de gebruiksgeschiedenis van de gebruiker op.",
        },
      ],
      rumble_items: [
        {
          Naam: "AWSALBCORS",
          Retentie: "1 week",
          Functie:
            "Amazon’s cookie voor CORS-toegang. Deze is nodig om CORS toegang te krijgen in Amazon.",
        },
        {
          Naam: "s",
          Retentie: "sessie",
          Functie:
            "Een sessiecookie die wordt gebruikt om de gebruiker te verbinden met zijn huidige sessie. Het wordt verwijderd wanneer het tabblad wordt gesloten en aangemaakt wanneer het tabblad wordt geopend.",
        },
        {
          Naam: "r",
          Retentie: "2 weken",
          Functie:
            "Deze cookie wordt geplaatst wanneer de gebruiker inlogt en wordt gebruikt om de gebruiker ingelogd",
        },
      ],
      slido_items: [
        {
          Naam: "__exponea_(*), xnpe_(*), __EX(*)",
          Retentie: "3 jaar",
          Functie:
            "Deze cookies worden gebruikt om informatie te verzamelen over hoe bezoekers functies en onze website gebruiken. We gebruiken de informatie om rapporten samen te stellen en onze website te verbeteren. De cookies verzamelen informatie op een manier die niemand direct identificeert totdat ze een Slido-account aanmaken.",
        },
        {
          Naam: "_fbp fr, act, oo, presence, spin, wd, xs, sb, sfau, wd",
          Retentie: "3 maanden",
          Functie:
            "Gebruikt door Facebook om een reeks advertentieproducten te leveren, zoals realtime bieden van externe adverteerders.",
        },
        {
          Naam: "Slido.Privacy",
          Retentie: "10 jaar",
          Functie:
            "Slaat de cookiestatus van de gebruiker op voor het huidige domein.",
        },
        {
          Naam: "slido_(*), Slido.(*), currentAccountUuid, AWSALB, _passenger_route",
          Retentie: "10 jaar",
          Functie:
            "Technisch, vereist voor onze infrastructuur (cookie bevat een token om de inlogsessie te verifiëren enz.).",
        },
        {
          Naam: "JSESSIONID, TSNGUID, optimizelyEndUserId, _gcl_au, _mkto_trk, s_(*), _persistenceTest",
          Retentie: "sessie",
          Functie:
            "We gebruiken de New Relic-service om de uptime/downtime-status van onze websites te controleren.",
        },
      ],
      zoom_items: [
        {
          Naam: "_zm_lang",
          Retentie: "1 jaar",
          Functie: "Slaat taalvoorkeuren op",
        },
      ],
    };
  },
  computed: {
    client() {
      return this.$store.state.Config.settings.client.name;
    },
  },
};
</script>

<style lang="scss" scoped>
html,
.cookie-doc,
.privacy-doc {
  padding: 50px;
  background: #fff;
}

a {
  color: blue;
  :hover {
    color: black;
  }
}
.elipsis-list {
  ul,
  li {
    list-style-type: circle !important;
  }
}
</style>