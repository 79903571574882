<template>
<div
    class="whereby-player-container col-md-12 col-lg-12"
  >
    <iframe
        id="whereby-iframe"
        class="whereby-player"
        frameborder="0"
        :src="wherebyLink"
        allow="camera; microphone; fullscreen; speaker; display-capture"
    ></iframe>
</div>
</template>
<script>
export default {
  name: "Whereby",
  props: ["currentUser", "settings"],
  computed: {
    wherebyLink() {
      let link = ""
      let url = this.settings.interactive_livestream.url
      let roomName = this.settings.interactive_livestream.roomName
      let iframeSource = this.settings.interactive_livestream.iframeSource
      let username = this.currentUser.username

      link = url + "/" + roomName + 
        "?embed&iframeSource=" + iframeSource +
        "&background=off&audio=off&leaveButton=off&chat=on&screenshare=off" +
        "&displayName=" + username
        
      return link
    }
  }
}
</script>
<style >
.whereby-player-container {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 56.25%;
}
.whereby-player {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 75%;
}

@media only screen and (max-width: 999px) {
  .whereby-player-container {
    padding-top: 75%;
  }
}
</style>