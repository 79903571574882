<template>
<div class="video-player-container col-sm-12 col-md-12">
  <iframe
    v-if="$i18n.locale == 'fr_FR'"
    :src="`https://vimeo.com/event/${settings.fr.livestream.id}`"
    id="vimeo-iframe"
    frameborder="0"
    allow="autoplay; fullscreen;"
    allowfullscreen
    data-vimeo-tracked="true"
    class="video"
    :class="{'full-height': !chatActive}"
  ></iframe>
  <iframe
    v-if="$i18n.locale == 'nl_NL'"
    :src="`https://vimeo.com/event/${settings.nl.livestream.id}`"
    id="vimeo-iframe"
    frameborder="0"
    allow="autoplay; fullscreen;"
    allowfullscreen
    data-vimeo-tracked="true"
    class="video"
    :class="{'full-height': !chatActive}"
  ></iframe>
</div>
</template>
<script>
export default {
  name: "Vimeo",
  props: ["settings", "chatActive"]
};
</script>
<style lang="scss">
.video-player-container {
  position: relative;
  padding-top: 56.25%;
  overflow: auto;
  width: 100%;
  height: auto;
  
  .video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}
</style>