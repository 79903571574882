<template>
  <b-row class="mt-3">
    <b-col
      cols="12"
      md="3"
      class="mb-2"
      v-for="attachment of attachments"
      :key="attachment.key"
    >
      <b-card
        :img-src="attachment.thumbnail"
        img-top
        :title="attachment.name"
        bg-variant="dark"
        text-variant="white"
      >
        <b-link
          :href="attachment.url"
          target="_blank"
          class="card-link stretched-link text-white"
        >
          {{ $t("attachmentsOpen") }}
        </b-link>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: "Attachments",
  computed: {
    attachments() {
      return this.$store.state.Config.settings.attachments;
    },
  },
};
</script>
<style lang="scss"></style>
