<template>
<div>
  <iframe 
    v-if="$i18n.locale == 'nl_NL'"
    :src="`https://vimeo.com/event/${settings.nl.chat.id}`"
    width="100%" 
    height="100%" 
    class="chat"
    frameborder="0">
  </iframe>
  <iframe 
    v-if="$i18n.locale == 'fr_FR'"
    :src="`https://vimeo.com/event/${settings.fr.chat.id}`"
    width="100%" 
    height="100%" 
    class="chat"
    frameborder="0">
  </iframe>
</div>
</template>
<script>
export default {
  name: "vimeo",
  props: ["currentUser", "settings"],
};
</script>
<style lang="scss">
.chat {
  height: 75%;
  min-height: 536.364px;
  min-width: 265.455px;
}
</style>