<template>
    <div class="languagepicker">
        <Header :languagepick="true"/>

        <div class="language-buttons">
            <button type="button" @click="pickLanguage('nl')" class="language-btn btn btn-secondary btn-lg">NL sessie - 11u</button>
            <button type="button" @click="pickLanguage('fr')" class="language-btn btn btn-secondary btn-lg push-right">session FR - 13h</button>
        </div>
    </div>
</template>
<script>
import Header from "@/components/Header.vue";

export default {
  name: "LanguagePick",
  components: {
    Header,
  },
  computed: {
      isLoggedIn() {
          return this.$store.getters["Auth/isLoggedIn"]
      }
  },
  methods: {
      pickLanguage(lang) {
        if (lang == "nl") {
            console.log("nl")
            localStorage.setItem("lang", "nl_NL")
            this.$i18n.locale = localStorage.getItem("lang")
            this.$i18n.fallbackLocale = localStorage.getItem("lang")
            
        } else if (lang == "fr") {
            localStorage.setItem("lang", "fr_FR")
            console.log("fr")
            this.$i18n.locale = localStorage.getItem("lang")
            this.$i18n.fallbackLocale = localStorage.getItem("lang")
        }

        this.$router.push("/home")
      }
  }
}
</script>

<style>
    .languagepicker {
        flex-grow: 1;
    }

    .language-buttons {
       position: absolute;
       width: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .language-btn {
        height: 150px;
        width: 200px;
    }
</style>