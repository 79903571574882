import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { domain, clientId, audience } from "../auth_config.json";
import { Auth0Plugin } from "./auth0";
import { BootstrapVue, IconsPlugin} from "bootstrap-vue";
import {i18n} from "@/plugins/i18n";
import FlagIcon from "vue-flag-icon";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faUser, faArrowLeft, faVideo, faComments, faPaperclip, faFilePdf, faMobile, faSignInAlt, faSignOutAlt, faHashtag } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VeeValidate from 'vee-validate';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import VueMeta from 'vue-meta'


library.add(faUserSecret, faUser, faArrowLeft, faVideo, faComments, faPaperclip, faFilePdf, faMobile, faSignInAlt, faSignOutAlt, faHashtag)

Vue.component('font-awesome-icon', FontAwesomeIcon)
import "./assets/custom.scss";
import "./assets/tribespot.scss";

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(FlagIcon);
Vue.use(VueMeta)
Vue.use(VeeValidate);
Vue.component(VueQrcode.name, VueQrcode);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
