<script>
import Vue from "vue";
import VueFreshchat from "vue-freshchat";


export default {
  props: ["currentUser", "settings"],
  el: "#main",
  data() {
      return {
          userExternalId: 1,
      }
  },
  computed: {
    appToken() {
      return this.$store.state.Config.settings.support.appToken
    },
    host() {
      return this.$store.state.Config.settings.support.host
    }
  },
  mounted() {
    if (this.host && this.appToken){
      Vue.use(VueFreshchat, { appToken: this.appToken, host: this.host });

    }
    this.$freshchat.setExternalId(this.userExternalId)
    this.$freshchat.open()
  },
  watch: {
    userEmail() {
        this.$freshchat.setUserProperties({ email: this.currentUser.email })
    },
  },
}
</script>