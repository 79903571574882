<template>
  <div class="homepage">
    <Header
      :currentUser="currentUser"
      :settings="settings" 
      :languagepick="false"
    />
    <Popup 
      :currentUser="currentUser"
      :settings="settings"
    />
    <div class="main">
      
      <!-- LIVESTREAM NO TABS -->
      <div class="row"> 
        <div class="col-md-9"></div>
        <div class="col-md-3 pull-right"> 
        <!-- <ChatQR 
        v-if="$i18n.locale == 'nl_NL'"
        class="qr-button float-right push-right"
        :currentUser="currentUser"
        :settings="settings"
        :chatType="settings.nl.chat.type"
        />
        <ChatQR 
        v-if="$i18n.locale == 'fr_FR'"
        class="qr-button float-right push-right"
        :currentUser="currentUser"
        :settings="settings"
        :chatType="settings.fr.chat.type"
        /> -->
        </div>
      </div>
      <div class="livestream-row row" v-if="settings.interactive_livestream.type == '' && settings.attachments.length == 0">
            <div
              class="video-player col-sm-12 col-md-12"
              :class="{ 'col-lg-9': chatActive, 'col-lg-12': !chatActive }"
            >
              
              <Vimeo
                class="video"
                :currentUser="currentUser"
                :settings="settings"
                :chatActive="chatActive"
                v-if="(settings.nl.livestream.type).toLowerCase() == 'vimeo'"
              />
              <div class="video-info" :class="{ 'info-push': !chatActive }">
                <h1 class="video-title text-color-title">{{ $t("livestream_title") }}</h1>
                <p class="video-description text-color-body">
                  {{ $t("livestream_description") }}
                </p>
              </div>
            </div>
            <div class="stream-chat-container col-md-12 col-lg-3">
              <!-- <Slido
                :currentUser="currentUser"
                :settings="settings"
                class="chat"
                v-if="(settings.chat.type).toLowerCase() == 'slido'"
              /> -->
              <VimeoChat
                :currentUser="currentUser"
                :settings="settings"
                class="chat"
                v-if="(settings.nl.chat.type).toLowerCase() == 'vimeo'"
              />
            </div>
          </div>


      <!-- TABS -->
      <b-tabs class="home-tabs" v-if="settings.interactive_livestream.type !== '' && settings.attachments.length !== 0">
        <b-tab title="Livestream" active>
          <template #title>
            <font-awesome-icon
              class="label-icon"
              icon="video"
            ></font-awesome-icon>
            <span class="tab-text">{{ $t("livestream") }}</span>
          </template>
          <div class="livestream-row row">
            <div
              class="video-container col-sm-12 col-md-12"
              :class="{ 'col-lg-9': chatActive, 'col-lg-12': !chatActive }"
            >
              <div class="video-player-container">
              <Vimeo
                :currentUser="currentUser"
                :settings="settings"
                :chatActive="chatActive"
                v-if="(settings.livestream.type).toLowerCase() == 'vimeo'"
              />
              <!-- <Zoom
                class="video"
                :currentUser="currentUser"
                :settings="settings"
                v-if="(settings.livestream.type).toLowerCase() == 'zoom'"
              />
              <Youtube
                class="video"
                :settings="settings"
                :chatActive="chatActive"
                v-if="(settings.livestream.type).toLowerCase() == 'youtube'"
              /> -->
              </div>
              <div class="video-info" :class="{ 'info-push': !chatActive }">
                <h1 class="video-title text-color-title">{{ $t("livestream_title") }}</h1>
                <p class="video-description text-color-body">
                  {{ $t("livestream_description") }}
                </p>
              </div>
            </div>
            <div class="stream-chat-container col-md-12 col-lg-3">
              <!-- <Slido
                :currentUser="currentUser"
                :settings="settings"
                class="chat"
                v-if="(settings.chat.type).toLowerCase() == 'slido'"
              /> -->
              <VimeoChat
                :currentUser="currentUser"
                :settings="settings"
                class="chat"
                v-if="(settings.chat.type).toLowerCase() == 'vimeo'"
              />
            </div>
          </div>
        </b-tab>
        <b-tab
          v-if="settings.interactive_livestream.type !== ''"
          @click="StopAllAudio()"
          title="Speeddate"
        >
          <template #title>
            <font-awesome-icon
              class="label-icon"
              icon="comments"
            ></font-awesome-icon>
            <span class="tab-text">{{ $t("conversation") }}</span>
          </template>
          <Whereby
            :currentUser="currentUser"
            :settings="settings"
            v-if="(settings.interactive_livestream.type).toLowerCase() == 'whereby'"
          ></Whereby>
        </b-tab>
        <b-tab v-if="settings.attachments.length !== 0" title="Bijlagen">
          <template #title>
            <font-awesome-icon
              class="label-icon"
              icon="paperclip"
            ></font-awesome-icon>
            <span class="tab-text">{{ $t("attachments") }}</span>
          </template>
          <Attachments></Attachments>
        </b-tab>
        <b-tab title="Instagram" v-if="(settings.social.type).toLowerCase() == 'instagram'">
          <template #title>
            <font-awesome-icon
              class="label-icon"
              icon="hashtag"
            ></font-awesome-icon>
            <span class="tab-text">{{ $t("Social") }}</span>
          </template>
          <Instagram

          />
        </b-tab>
      </b-tabs>
    </div>
    <FreshChat
      v-if="(settings.support.type).toLowerCase() == 'freshchat'"
      :currentUser="currentUser"
      :settings="settings"
      class="chat"
    />
    <!-- <Footer /> -->
  </div>
</template>

<script>
import Zoom from "@/components/video/Zoom.vue";
import Youtube from "@/components/video/Youtube.vue";
import Vimeo from "@/components/video/Vimeo.vue";
import VimeoChat from "@/components/chat/VimeoChat.vue";
import Whereby from "@/components/interactive-livestream/Whereby.vue";
import FreshChat from "@/components/chat/FreshChat.vue";
import Slido from "@/components/chat/Slido.vue";
import Attachments from "@/components/Attachments.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Popup from "@/components/Popup.vue";
import ChatQR from "@/components/chat/ChatQR";
import Instagram from "@/components/social/Instagram";

export default {
  name: "Home",
  components: {
    Header,
    // Footer,
    Popup,
    // Slido,
    Vimeo,
    VimeoChat,
    FreshChat,
    // Zoom,
    // Youtube,
    Whereby,
    Attachments,
    // ChatQR,
    Instagram
  },
  computed: {
    settings() {
      return this.$store.state.Config.settings;
    },
    chatActive() {
      return this.settings.nl.chat.type !== "";
    },
    currentUser() { 
      return this.$store.getters["Auth/currentUser"]
    }
  },
  methods: {
    StopAllAudio() {
      var iframe = this.$el.querySelector("iframe");
      var video = this.$el.querySelector("video");
      if (iframe) {
        var iframeSrc = iframe.src;
        iframe.src = iframeSrc;
      }
      if (video) {
        video.pause();
      }
    },
  },
  created() {
    this.$i18n.locale = localStorage.getItem("lang")
    this.$i18n.fallbackLocale = localStorage.getItem("lang")
  }
};
</script>

<style lang="scss">
.homepage {
  flex-grow: 1;
}
.main {
  max-width: 1600px;
  margin: 0 auto;
  height:100%;
  padding: 0.6923em 2.3077em;
  padding-bottom: 0;
}

.video-info {
    position: relative;
    display: flex;
    margin-top: 15px;
}

.stream-chat-container {
  position: relative;
  width: 100%;

  .chat {
    height: 95.3%;
    min-height: 536.364px;
    min-width: 265.455px;
  }
}

.push-right {
  float: right;
  right: 0;
}


@media only screen and (max-width: 750px) {
  .nav {
    flex-wrap: nowrap;
  }
  .qr-button { 
    display: none;
  }
}
@media only screen and (max-width: 472px) {
  .nav-tabs {
    flex-wrap: nowrap;
  }
  .tab-text {
    display: none;
    float: right;  
  } 
}

// @media only screen and (max-width: 1375px) {
  // .stream-chat-container {
  //   height: 100%;
  // }
// }

@media only screen and (max-width: 999px) {
  .main {
    padding-top: 35px;
    padding-left: 35px;
    padding-right: 35px;
    margin: 0 0;
  }

  .video-player-container {
    width: 100%;
    padding-top: 75%;
  }
 
  .stream-chat-container {
    margin-bottom: 50px;
    // min-height: 750px;
  }
}
@media only screen and (max-width: 750px) {
  .video-info {
    // margin-top: 60px;
  }
}

// @media only screen and (max-width: 650px) {
//   .video-info {
//     margin-top: 100px;
//   }
//   .video-description {
//     display: none;
//   }
// }

// @media only screen and (max-width: 400px) {
// .video-player-container .video-info {
//   margin-top: 0px !important;
// }
// }
@media only screen and (max-width: 320px) {
  .video-info {
    display: none;
  }
}
</style>
