<template>
    <footer>
        <div class="footer-container">
            <p>Powered by <a href="https://www.tribespot.be">Tribespot</a></p>
        </div>
        <cookie-law v-if="language == 'fr_FR'" :buttonLink="`/cookie-privacy-fr`" theme="dark-lime"></cookie-law>
        <cookie-law v-if="language == 'nl_NL'" :buttonLink="`/cookie-privacy-nl`" theme="dark-lime"></cookie-law>
    </footer>
</template>

<script>
import CookieLaw from 'vue-cookie-law'
  export default {
    components: { CookieLaw },
    computed: {
        language() {
            return this.$root.$i18n.locale
        }
    }
  }
</script>

<style lang="scss">
$color-primary: var(--theme-color-primary);
$color-secondary: var(--theme-color-secondary);
$color-warning: var(--theme-color-warning);
$color-success: var(--theme-color-success);
$color-danger: var(--theme-color-danger);

$font-family: var(--theme-font-family);

// footer {
    
//   width:100%;
//   bottom: 0;
//   position: absolute;
// }

footer {
    // position:fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $color-secondary;
    
    .footer-container {
        display:flex;
        flex-wrap: wrap;
        flex-direction:column;
        align-items:center;
        color: #fff;
    }
    p {
        font-size: 15px;
        margin: 1em;
    }
}

</style>