import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "@/views/Home.vue";
import BasicProfile from "@/views/BasicProfile.vue";
import Auth0Profile from "@/views/Auth0Profile.vue";
import Admin from "@/views/Admin.vue";
import Authentication from "@/views/Authentication.vue";
import ComingSoon from "@/views/ComingSoon.vue";
import MobileChat from "@/views/MobileChat.vue"
import store from "@/store/index";
import ZoomPlayer from "@/components/video/ZoomPlayer.vue";
import CookiePrivacyDoc_NL from "@/components/GDPR/CookiePrivacyDoc_NL.vue";
import CookiePrivacyDoc_FR from "@/components/GDPR/CookiePrivacyDoc_FR.vue";
import LanguagePick from "@/views/LanguagePick.vue";


import { authGuard } from "../auth0/authGuard";
import { loadLanguageAsync } from "../plugins/i18n";

Vue.use(VueRouter);

let routes: Array<RouteConfig> = [];

routes = [
  {
    path: "/",
    alias: ["/language"],
    name: "LanguagePick",
    meta: {
      authRequired: false,
    },
    component: LanguagePick,
  },
  {
    path: "/livestream",
    alias: ["/home"],
    name: "Home",
    meta: {
      authRequired: true,
    },
    component: Home,
  },
  {
    path: "/profile-b",
    name: "profileBasic",
    meta: {
      authRequired: true,
    },
    component: BasicProfile,
  },
  {
    path: "/profile-a",
    name: "profileAuth0",
    meta: {
      authRequired: true,
    },
    component: Auth0Profile,
  },
  {
    path: "/admin",
    name: "admin",
    meta: {
      authRequired: true,
    },
    component: Admin,
  },
  {
    path: "/authentication",
    name: "authentication",
    component: Authentication,
  },
  {
    path: "/zoom",
    name: "zoom",
    component: ZoomPlayer,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/coming-soon",
    name: "comingSoon",
    component: ComingSoon
  },
  {
    path: "/mobile",
    name: "mobileChat",
    component: MobileChat,
  },
  {
    path: "/cookie-privacy-nl",
    name: "cookie",
    component: CookiePrivacyDoc_NL,
  },
  {
    path: "/cookie-privacy-fr",
    name: "cookie",
    component: CookiePrivacyDoc_FR,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  await store.dispatch("Config/getSettings");
  await loadLanguageAsync(store.state.Config.settings.languages);
  const loginRequired = store.state.Config.settings.features.loginRequired;
  const authentication = store.state.Config.settings.features.authentication;
  const comingSoon = store.state.Config.settings.features.coming_soon;
  const bypass = store.state.Config.settings.features.bypass;

  if (bypass && to.query.pass == "tribespotFTW!") {
    localStorage.bypassed = true
    return next("/")
  }

  if (!localStorage.bypassed && to.path !== "/coming-soon" && comingSoon) {
    return next("/coming-soon")
  } 

  if (to.path == "/coming-soon" && !comingSoon) {
    return next("/")
  }
  
  if (!loginRequired || !to.meta?.authRequired) {
    return next();
  }

  if (authentication == "auth0") {
    authGuard(to, from, next);
  } else if (authentication == "basic" || authentication == "code") {
    const loggedIn = localStorage.getItem("user");
    if (!loggedIn) {
      return next("/authentication");
    }
  }
  
  return next();
});

export default router;
