<template>
<div>
  <!-- <b-toast id="message-toast" title="User message" static no-auto-hide> 
    {{ this.message.text }}
    <a>{{ this.message.url }}</a>
  </b-toast> -->
</div>
</template>
<script>
  import axios from 'axios'
  export default {
    name: "Popup",
    props: ['currentUser', 'settings'],
    data() {
      return {
        message: {
          "email": "",
          "text": "",
          "url": ""
        }
      }
    },
    watch: {
      message: function() {
        if (this.message.text !== undefined && this.isLoggedIn) {
          this.makeToast()
        }
      }
    },
    computed: { 
      isLoggedIn() {
        return this.$store.getters["Auth/isLoggedIn"]
      },
    },
    methods: {
      makeToast() {
        this.$root.$bvToast.toast(`${this.message.text}`, {
          title: "New message from admin!",
          href: this.message.url,
          appendToast: true,
          variant: 'info',
          autoHideDelay: 20000,
          toaster: 'b-toaster-top-center',
        })
      },
      getMessageByEmail() {
      const isEqual = (...objects) => objects.every(obj => JSON.stringify(obj) === JSON.stringify(objects[0]));
       axios.get(process.env.VUE_APP_API_URL + '/message.php?email=' + this.currentUser.email)
       .then((response) => {
          if (!isEqual(this.message, response.data)) {
            this.message = response.data
          }
        })
      }
    },
    mounted() { 
      if (this.isLoggedIn) {
        this.getMessageByEmail()
        
        setInterval(function () {
          this.getMessageByEmail()
          console.log(this.message)
        }.bind(this), 30000)
      }
    }
  }
</script>
<style lang="scss">
  .toast {
    color: black;
    a {
      color: black;
    }
  }
</style>