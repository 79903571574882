<template>
    <header class="main-header color-primary text-color-header">
    <nav class="brand-nav">
      <router-link
        to="/"
        class="logo text-replace"
        :style="{ backgroundImage: 'url(' + this.settings.client.logo + ')' }"
        >Tribespot</router-link
      >
    </nav>
    <nav class="actions push">
        <!-- Admin link -->
        <!-- <router-link v-if="this.isLoggedIn" class="nav-item profile-nav" to="/admin">
          <img class="nav-img nav-icon" src="/static/admin.png"/><span class="menu-text">{{ $t('admin') }}</span>
        </router-link> -->

        <!-- Profile link -->
        <!-- <div v-if="this.isLoggedIn && this.loginRequired"> -->
        <!-- <a href="#" class="notifications sprite nav-icon" :style="{ backgroundImage: 'url(' + this.getSettings.client.notification + ')' }"></a> -->

        <!-- Basic authentication -->
        <!-- <router-link class="nav-item profile-nav" v-if="authenticationType == 'basic'" to="/profile-b">
            <img class="nav-img nav-icon" :src="this.getSettings.client.profilepicture"/><span class="menu-text">{{ $t('profile') }}</span>
          </router-link> -->

        <!-- Auth0 -->
        <!-- <router-link class="nav-item profile-nav" v-if="authenticationType == 'auth0'" to="/profile-a">
            <img class="nav-img nav-icon" :src="this.getSettings.client.profilepicture"/><span class="menu-text">{{ $t('profile') }}</span>
          </router-link>
        </div> -->

        <!-- Login / Logout -->
        <!-- Basic authentication -->
        <div
          v-if="
            !languagepick &&
            !$auth0.loading &&
            this.loginRequired && 
            !settings.features.coming_soon
          "
        >
          <a class="nav-item" v-if="!this.isLoggedIn" @click="login">
            <font-awesome-icon
              :icon="logoutIcon"
              class="nav-icon log-icon"
            />
            <span class="menu-text">{{ $t("login") }}</span>
          </a>
          <a class="nav-item" v-if="this.isLoggedIn" @click="logout">
            <font-awesome-icon
              :icon="loginIcon"
              class="nav-icon log-icon"
            />
            <span class="menu-text">{{ $t("logout") }}</span>
          </a>
        </div>

        <!-- Language -->
        <select
          class="selectpicker language-picker color-primary text-color-header"
          data-width="fit"
          v-model="$root.$i18n.locale"
          v-if="languages.length > 1"
        >
          <option v-for="entry in languages" :value="entry" :key="entry">
            {{ $t(`languages.${entry}`) }}
          </option>
        </select>
    </nav>
  </header>
</template>

<script>
import { faSignInAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

export default {
  props: ["languagepick"],
  data() {
    return {
      isActive: true,
    };
  },
  methods: {
    login() {
      if (this.authenticationType == "basic" || this.authenticationType == "code") {
        this.$router.push("/authentication");
      }  else if (this.authenticationType == "auth0") {
        this.$auth0.loginWithRedirect();
      }
    },
    logout() {
      if (this.authenticationType == "basic" || this.authenticationType == "code") {
        this.$store.dispatch("Auth/signOut");
        this.$router.push("/authentication");
      }  else if (this.authenticationType == "auth0") {
        if (this.settings.features.loginRequired) {
          this.$auth0.logout({
            returnTo: window.location.origin,
          });
        } else {
          this.$auth0.logout({
            returnTo: "/",
          });
        }
      }
    },
  },
  computed: {
    settings() {
      return this.$store.state.Config.settings;
    },
    languages() {
      return this.settings.languages;
    },
    authenticationType() {
      return this.$store.getters["Config/authenticationType"]
    },
    loginRequired() {
      return this.settings.features.loginRequired;
    },
    isLoggedIn() {
      return this.$store.getters["Auth/isLoggedIn"];
    },
    logoutIcon() {
      return faSignOutAlt
    },
    loginIcon() {
      return faSignInAlt
    }
  },
};
</script>

<style lang="scss">
.main-header {
  position: relative;
  z-index: 100;
  display: flex;
  width: 100%;
  padding: 0.6923em 2.3077em;
  min-height: 70px;
  margin-bottom: 20px;

  .actions {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
  
  > * {
    display: flex;
    align-items: center;
  }
}

.brand-nav {
  width: 300px;
  margin-right: 2em;
  .logo {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 100%;
    width: 100%;
    :hover {
      cursor: pointer;
      img {
        opacity: 0.8;
      }
    }
  }
}


.nav-item {
  margin-left: auto;
  display: flex;
  margin-left: 20px;
  .menu-text {
    font-size: 19px;
    font-weight: bold;
    // commented for Randstad 
    // text-transform: uppercase;
  }
  .nav-icon {
    width: 20px;
    height: 20px;
    margin: auto 0.6154em;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}

.nav-item:hover {
    cursor: pointer;
    opacity: 0.8;
}

.language-picker {
  font-size: 19px;
  margin-left: 10px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
}

.language-picker:hover {
    cursor: pointer;
    opacity: 0.8;
}

@media only screen and (max-width: 472px) {
  .menu-text {display: none;}
  
}
</style>
